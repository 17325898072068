<template>
  <div class="brain-library">
    <header class="brain-library__header">
      <i class='bx bx-plus header-button' ></i>
      <div class="brain-library__preferences">
        <i class='bx bx-menu header-button' ></i>
        <Input class="brain-library__search" placeholder="Search files..." />
      </div>
      <!-- Creating a store Setting -->

      <!-- store filter -->
    </header>
    <main class="brain-library__main">
      <div class="brain-library__component" v-for="(brain, index) in brains" :key="brain._id">
        <div class="brain-library__component-header">
           <i :class="['bx', (showFiles && index === showFilesIndex) ? 'bx-chevron-up' : 'bx-chevron-down']" @click="toggleBrainAction(brain, index)"></i>
          {{ brain.label }}
        </div>
         <ul v-if="(showFiles && index === showFilesIndex)" class="brain-library__list-items" :class="{'grid-layout': mode === 'grid', 'list-layout': mode === 'list'}">
          <li class="brain-library__list-item" v-for="(file, fileIndex) in brain.files[0]" :key="file._id">
            <div class="brain-library__list-item__body">
             {{ fileIndex + 1 + '.   ' + file.name }}
          </div>
           <div class="brain-library__list-item__tools">
           <button
            class="brain-library__component-btn ed-btn__blim"
            :title="brain.status ? 'Unlink Brain from Central Store' : 'Link Brain to Central Store'"
            @click="toggleLinkedStatus(index, file.linked_status, brain.vectorId, file.file_id)">
            <span>{{ file.linked_status}}</span>
            <i class="box-icon bx" :class="file.linked_status.toLowerCase() === 'unlink' ? 'bx-minus' : 'bx-plus'"></i>
          </button>
          <!-- copy store Id -->
          <button class="brain-library__component-btn ed-btn__blim" title="Copy Brain Id">
            <i class="box-icon bx bx-copy"></i>
          </button>
          <!-- delete vector store -->
          <button class="brain-library__component-btn ed-btn__blim ed-btn__blim" title="Delete Brain Store" @click="handleFileDelete(file.file_id, brain, index)">
            <i class="box-icon bx bx-trash"></i>
          </button>
          </div>
        </li>
      </ul>
      </div>
    </main>
    <footer class="brain-library__footer"></footer>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      files: [],
      showFiles: false,
      showFilesIndex: null,
    };
  },
  name: 'Brain Library Widget',
  computed: {
    brains() {
      const totalBrains = this.$store.state.eddie.brains;
      const curatedBrains = totalBrains.map((brain) => {
        console.log('xvf', 'Each Single Brain', brain);
        let temp = {};
        const selectedStore = this.selectedIds.find((each) => each === brain.id);
        const status = !!selectedStore;
        if (brain.files) {
          temp = { ...brain, status };
        } else {
          temp = { ...brain, status, files: [] };
        }
        return temp;
      });
      console.log('total brain', totalBrains, curatedBrains);
      return curatedBrains;
    },
    selectedIds() {
      return this.$store.state.auth.user.connectedBrains;
    },
  },
  mounted() {
    this.$store.dispatch('eddie/fetchBrains');
  },
  methods: {
    async fetchVectorStoreFiles(vectorStoreId, brainIndex) {
      this.$store.dispatch('eddie/fetchBrainFiles', { vectorStoreId, brainIndex });
    },
    // async fetchVectorStoreFiles(vectorStoreId, brainIndex) {
    //   try {
    //     const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/vector-store/files-count`, {
    //       id: vectorStoreId,
    //     });
    //     const files = response.data.file || [];
    //     this.brains[brainIndex].files = files;
    //     const temp = [...this.brains];
    //     this.brains = [];
    //     setTimeout(() => {
    //       this.brains = temp;
    //     }, 3000);
    //     console.log('Files:', this.brains[brainIndex].files);
    //   } catch (error) {
    //     console.error('Error fetching suggestions:', error);
    //   }
    // },
    async toggleBrainAction(brain, brainIndex) {
      // const action = brain.status ? 'unlink' : 'link';
      this.showFiles = !this.showFiles;
      this.showFilesIndex = brainIndex;
      console.log(this.showFilesIndex);
      console.log(brainIndex);
      const vectorStoreId = brain.vectorId;
      console.log(brain.vectorId);
      this.fetchVectorStoreFiles(vectorStoreId, brainIndex);
    },
    async toggleLinkedStatus(index, status, vectorId, fileId) {
      if (status === 'Unlink') {
        this.handleFileUnlink(vectorId, fileId, index);
      } else {
        this.handleFileLink(vectorId, fileId, index);
      }
    },
    async handleFileUnlink(vectorStoreId, fileId, index) {
      console.log(vectorStoreId, fileId);
      console.log('File Unlinked');
      try {
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/file-unlink`, {
          vectorStoreId, fileId,
        });
        console.log(response.data.message);
        this.fetchVectorStoreFiles(vectorStoreId, index);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    async handleFileLink(vectorStoreId, fileId, index) {
      console.log(vectorStoreId, fileId);
      console.log('File Linked');
      try {
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/file-link`, {
          vectorStoreId, fileId,
        });
        console.log(response.data.message);
        this.fetchVectorStoreFiles(vectorStoreId, index);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    async handleFileDelete(fileId, brain, index) {
      console.log(fileId);
      try {
        const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/file-delete`, {
          fileId,
        });
        console.log(response.data.message);
        // this.$store.dispatch('eddie/fetchBrains');
        const vectorStoreId = brain.vectorId;
        console.log(brain.vectorId);
        this.fetchVectorStoreFiles(vectorStoreId, index);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brain-library {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;

  .header-button {
    border: 1px solid $color-grey-3;
    padding: 10px;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-3;
    }
  }

  &__search {
    border: 1px solid $color-grey-3;
    width: 300px;
    border-radius: 6px;
    padding: 10px;

    &:focus {
      outline: none;
    }
  }

  &__list-items {
    padding: 6px;
    margin: 0;
    list-style-type: none;

    &.grid-layout {
      display: flex;
      flex-wrap: wrap;
      li {
        // flex: 0 0 3rem;
        width: 18rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid $color-grey-3;
        margin: 0.5rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        background: rgba(0, 0, 0, 0.1);

        &:hover {
          background: rgba(101, 157, 189, 0.1);
        }
      }
    }

    &.list-layout {
      li {
        padding: 0.5rem 0;
        border-bottom: 1px solid $color-grey-3;
      }
    }
  }

  &__list-item {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // border-bottom: 1px solid rgba(165, 165, 165, 0.1803921569);
    padding: 0.5rem 0;

    &__body {
      margin-left: 30px;
    }

    &__tools {
      display: flex;
      gap: 0.8rem;
      margin-left: 60px;
      margin-top: 20px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    color: black;
    border-bottom: 1px solid var(--border-color-1);
  }

  &__preferences {
    display: flex;
    margin-left: 10px;

    .box-icon {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #007bff;
      }
    }
  }

  &__main {
    flex-grow: 1;
    padding: 16px;
    overflow-y: auto;
  }

  &__component {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    /* align-items: center; */

    &:hover {
      background-color: $color-grey-3;
    }

    &-header {
      font-size: 14px;
      font-weight:500;
      color: #333;
    }

    &-origin {
      font-size: 12px;
      color: #666;
    }

    &-preferences {
      display: flex;
      gap: 30px;
    }

    &-name {
    margin-left: 10px;
    }

    &-btn {
      display: flex;
      align-items: center;
      /* background-color: #007bff; */
      /* color: #fff; */
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      /* border: none; */
      padding: 6px;
      height: 2rem;

      span {
        margin-right: 8px;
      }

      /* .box-icon {
        font-size: 20px;
      }

      &:hover {
        background-color: #0056b3;
      }

      &--delete {
        background-color: #dc3545;

        &:hover {
          background-color: #c82333;
        }
      } */
    }
  }

  &__component-preferences {
    margin-top: 1rem;
    align-self: end;
  }

  // &__footer {
  //   padding: 16px;
  //   background-color: #eeeeee;
  //   color: #fff;
  //   text-align: center;
  // }
}

</style>
